.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-bottom: 1px solid black;
}

.App-header a {
  margin: 0 16px;
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background: white !important;
  border-color: #DDE4EA !important;
  color: #DDE4EA !important;
}

.ant-select-tree-switcher {
  width: 10px !important;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  border-color: #f40266 !important;
}


.ant-form-item-has-error label {
  color: #f40266 !important;
}

.ant-form-item-has-error label span:last-child {
  color: black !important;
}

.ant-upload-draggable-list-item {
  /* border-top: 2px dashed rgba(0, 0, 0, 0);
  border-bottom: 2px dashed rgba(0, 0, 0, 0); */
}
.ant-upload-draggable-list-item.drop-over-downward {
  /* border-bottom-color: red; */
  padding-bottom: 40px;
  position: relative;
}
.ant-upload-draggable-list-item.drop-over-downward::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0px;
  bottom: 19px;
  left: 0;
  border-top: 2px dashed rgba(0, 0, 0, 1);
}

.ant-upload-draggable-list-item.drop-over-upward {
  position: relative;
  padding-top: 40px;
}

.ant-upload-draggable-list-item.drop-over-upward::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0px;
  top: 19px;
  left: 0;
  border-bottom: 2px dashed rgba(0, 0, 0, 1);
}

.ant-form-item {
  margin-bottom: 15px !important;
}

/* Global style scroll bar */
*::-webkit-scrollbar {
  width: 9px;
  position: relative;
}

/* Track */
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background: white;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #2E60CA;
  border-radius: 0px;
  margin-left: 2px;
  border-left: 2px white solid;
  border-right: 2px white solid;
}

#root {
  height: 1px;
  min-height: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
body {
  background-color: white;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.ant-message{
  z-index: 5;
}
@media (min-width: 318px) and (max-width: 576px) {
  body {
    padding: 0;
  }
  #root {
    /* height: calc(100% - 64px); */
    height: auto !important;
    width: 100% !important;
    position: relative;
    top: 0;
    padding-top: 64px;
    padding-bottom: 0;
  }
}
@media (min-width: 991px) {
  body {
    position: relative !important;
  }
}

@media (min-width: 318px) and (max-width: 576px) {
  input, TextArea{
    font-size: 16px !important;
  }
}

.ant-select-dropdown{
  z-index: 20;
}


#suggest{
  height: 40px !important;
  border-radius: 8px;
}

*{
  animation-duration: 0s;
  transition-duration: 0s;
}

.react-tel-input.phone-input .form-control {
  width: 100%;
  border: 1px solid #DDE4EA;
  height: 40px;
}
.react-tel-input.phone-input .flag-dropdown {
    background-color: transparent;
}
